import React from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

function UseragentOutput(props) {
  const userAgentArray = props.userAgentArray;

  function copyWaitTextHandlerList(event) {
    const target = event.target;
    if (target.matches("button")) {
      target.textContent = "Copied!";
      setTimeout(() => (target.textContent = "Copy"), 600);
    }
  }

  function copyALLWaitTextHandler(event) {
    const target = event.target;
    target.textContent = "Copied!";
    setTimeout(() => (target.textContent = "Copy All"), 600);
  }

  function fullArrayList() {
    const ArrayMap = userAgentArray.map(
      (userAgent, index) =>
        userAgent.OS +
        "\t" +
        userAgent.CPU +
        "\t" +
        userAgent.Browser +
        "\t" +
        userAgent.Engine
    );
    return ArrayMap.join("\n");
  }

  return (
    <div className="userAgentOutputContainer">
      <div className="userAgentList">
        <table className="agent-tbl">
          <thead>
            <tr>
              <th>Operating System</th>
              <th>CPU Architecture</th>
              <th>Browser</th>
              <th>Engine</th>
              <th>
                <CopyToClipboard
                  text={fullArrayList()}
                  options={{ format: "text/plain" }}
                >
                  <button className="btn-agnt" onClick={copyALLWaitTextHandler}>
                    <strong>Copy All</strong>
                  </button>
                </CopyToClipboard>
              </th>
            </tr>
          </thead>
          <tbody onClick={copyWaitTextHandlerList}>
            {userAgentArray.map((userAgent, index) => (
              <tr key={index}>
                <td>{userAgent.OS}</td>
                <td>{userAgent.CPU}</td>
                <td>{userAgent.Browser}</td>
                <td>{userAgent.Engine}</td>
                <td>
                  <CopyToClipboard
                    options={{ format: "text/plain" }}
                    text={
                      userAgent.OS +
                      "\t" +
                      userAgent.CPU +
                      "\t" +
                      userAgent.Browser +
                      "\t" +
                      userAgent.Engine
                    }
                  >
                    <button className="btn-agnt">Copy</button>
                  </CopyToClipboard>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UseragentOutput;
