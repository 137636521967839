import UAParser from "ua-parser-js";

import React, { useState } from "react";

import "./App.css";

import UseragentOutput from "./outputComponent/UseragentOutput";

function App() {
  const [query, setQuery] = useState("");
  const [userAgentList, setUserAgentList] = useState([]);

  function parseAgentHandler() {
    const queryArray = query
      .replace(/\r\n/g, "\n")
      .split("\n")
      .filter((line) => line);

    const userAgentArray = queryArray.map((text) => parseUserAgentText(text));

    setUserAgentList(userAgentArray);
  }

  function parseYoursHandler() {
    setQuery(parser.getUA());
    setUserAgentList([parseUserAgentText(parser.getUA())]);
  }
  const parser = new UAParser();
  function parseUserAgentText(text) {
    parser.setUA(text);
    const parseResult = parser.getResult();

    return {
      OS:
        parseResult.os.name === undefined
          ? "Unknown"
          : parseResult.os.name + " " + parseResult.os.version + "",
      CPU:
        parseResult.cpu.architecture === undefined
          ? "Unknown"
          : parseResult.cpu.architecture + "",
      Browser:
        parseResult.browser.name === undefined
          ? "Unknown"
          : parseResult.browser.name + " " + parseResult.browser.version + "",
      Engine:
        parseResult.engine.name === undefined
          ? "Unknown"
          : parseResult.engine.name + " " + parseResult.engine.version + "",
    };
  }

  function clearAllHandler() {
    setUserAgentList([]);
    setQuery("");
  }

  let outputSection;
  if (Object.keys(userAgentList).length) {
    outputSection = <UseragentOutput userAgentArray={userAgentList} />;
  } else {
    outputSection = <div />;
  }

  return (
    <div className="App">
      <div className="HeaderSection">
        <h1>Multi User Agent Converter</h1>
        User agents are a header request that websites use to know your
        platform, they typically come in a complicated string that may be
        difficult for some to understand.
        <ul>
          <li>
            Ever wanted to know "What is my user agent", use the "Try Yours?"
            button below.
          </li>
          <li>
            Or paste in single user agents or multiple user agents seperated
            with a new line or copied straight from Excel.
          </li>
          <li>
            The copy button will put the parsed user agent onto your clipboard,
            tabbed out so you can paste directly into Excel if required.
          </li>
          <li>
            The user agent parser uses an awesome library called{" "}
            <a
              href="https://github.com/faisalman/ua-parser-js"
              target="_blank"
              rel="noopener noreferrer"
            >
              UAParser.js
            </a>
            .
          </li>
          <li>
            For any queries feel free to contact me at:{" "}
            <a href="mailto: kelvin.innovator@gmail.com">
              kelvin.innovator@gmail.com
            </a>
            .
          </li>
        </ul>
        <p></p>
        <p></p>
        <div className="textContainer">
          <textarea
            className="input"
            rows="7"
            name="query"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Paste your user agent here!"
            spellCheck="false"
          />
          <button className="clear-btn" onClick={clearAllHandler}>
            &#10006;
          </button>
        </div>
        <div className="functions">
          <div className="formSection">
            <button className="button" onClick={parseYoursHandler}>
              Try Yours?
            </button>
            <button
              className="button"
              onClick={parseAgentHandler}
              type="button"
            >
              Parse Above
            </button>
          </div>
        </div>
      </div>
      {outputSection}
    </div>
  );
}

export default App;
